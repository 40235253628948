.aside {
  grid-area: b;
}

@media screen and (min-width: 64rem) {
  .main {
    display: grid;
    gap: 1rem;
    grid-template: "a b" auto / 1fr 0.6fr;
  }
}
