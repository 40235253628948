.fieldset {
  /** Re-applying a fieldset's border gives a more consistent border style with other elements */
  border: 1px solid #000000;
  box-sizing: border-box;
  margin: 0;
}

.input:focus + .label {
  outline: auto;
}

.input:checked + .label,
.input:checked + .label:hover {
  background-color: #000000;
}

.label {
  border: 1px solid #000000;
  background-color: transparent;
  display: inline-block;
  border-radius: 1em;
  padding: 0.25em 0.75em;
  margin: 0.25em;
}

.label:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
