.item {
  position: relative;
}

.item__img {
  background-color: darkgray;
  border: 2px solid silver;
  display: block;
  height: 4rem;
  width: 4rem;
}

.item__count {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0.5em,
    rgba(0, 0, 0, 0)
  );
  border: 0px;
  box-sizing: border-box;
  color: white;
  left: 2px;
  margin: 0;
  padding: 0.25em;
  position: absolute;
  text-align: right;
  top: 2px;
  width: calc(100% - 4px);
}

.Junk {
  border-color: silver;
}

.Basic {
  border-color: black;
}

.Fine {
  border-color: teal;
}

.Masterwork {
  border-color: green;
}

.Rare {
  border-color: gold;
}

.Exotic {
  border-color: orangered;
}

.Ascended {
  border-color: magenta;
}

.Legendary {
  border-color: purple;
}
