.nav {
  align-items: center;
  display: grid;
  gap: 0.5rem;
  grid-template:
    "home null settings" auto
    "links links links" auto / auto 1fr auto;
  margin: 0 0 0.5rem 0;
  padding: 0 0 0.5rem 0;
}

@media screen and (min-width: 30rem) {
  .nav {
    gap: 0;
    grid-template: "home links settings" auto / auto 1fr auto;
  }
}

.nav__list {
  display: flex;
  grid-area: links;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__item {
  margin: 0 0.5rem;
}

.nav__item:first-of-type {
  margin-left: 0;
}

.nav__item > a {
  color: white;
  padding: 0.25em;
}

.nav__item > a > img {
  filter: invert(1);
  height: 1.25em;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 1.25em;
}
