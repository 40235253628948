/** Non-collapsing area */
.tab {
  align-items: flex-start;
  border: 1px solid black;
  display: grid;
  grid-template:
    "heading control" auto
    "aside control" auto / 1fr auto;
  margin: 0;
}

.tab--closed {
  margin: -1px 0;
}

@media screen and (min-width: 24rem) {
  .tab {
    grid-template: "heading aside control" auto / 1fr auto auto;
  }
}

.tab__aside {
  grid-area: aside;
  padding: 0.375rem 0.25rem;
}

.tab__control {
  font-size: 1rem;
  grid-area: control;
  padding: 0.375rem 0.25rem 0.375rem 0;
  user-select: none;
}

.tab__heading {
  font-size: 1rem;
  font-weight: normal;
  grid-area: heading;
  user-select: none;
}

.tab__heading__padding {
  padding: 0.375rem 0 0.375rem 0.25rem;
}

/** Preserve line-height */
.tab__heading:empty::before {
  content: " ";
  display: inline-block;
}

/** Collapse area */
.folder {
  box-sizing: border-box;
  min-height: 4rem;
  padding: calc(2px + 0.5rem) 0.5rem;
}
