.dialog[open] {
  background-color: #111;
  box-sizing: border-box;
  color: white;
  display: grid;
  grid-template: "formA" auto "main" 1fr "formB" auto / 1fr;
  height: 36rem;
  width: 28rem;
}

.dialog__form--a__img {
  height: 1em;
  width: 1em;
}

.dialog__form--a {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.dialog__form--b {
  text-align: right;
}
