.aside {
  grid-area: c;
}

.main {
  display: grid;
  gap: 0.5rem;
  align-items: flex-start;
  grid-template:
    "a" auto
    "b" auto
    "c" auto / 1fr;
}

@media screen and (min-width: 64rem) {
  .main {
    align-items: initial;
    display: grid;
    gap: 1rem;
    grid-template:
      "a c" auto
      "b c" auto / 1fr 1fr;
  }
}

@media screen and (min-width: 90rem) {
  .main {
    grid-template: "a b c" auto / 1fr 1fr 1fr;
  }
}
