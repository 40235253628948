.button {
  background-color: #000044;
  border-radius: 0.25rem;
  border: 0;
  box-shadow: inset 1px 1px 2px #444, inset -1px -1px 2px #222,
    2px 2px 4px #111111, -2px -2px 5px #333333;
  color: white;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
}

.button > img {
  filter: invert(1);
  height: 1.25em;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 1.25em;
}

.working {
  animation: spin 4s infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
