.grid {
  display: grid;
  flex: 1 0 auto;
  grid-template:
    "nav" auto
    "main" auto / 1fr;
}

.heading--1 {
  align-self: center;
  grid-area: heading1;
}

.heading--2 {
  align-self: center;
  grid-area: heading2;
}

.main {
  grid-area: main;
}

.nav {
  grid-area: nav;
}

.nav__list {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 6rem 6rem;
  justify-content: center;
  list-style: none;
}

.nav__item {
  flex: 0 0 6rem;
  width: 100%;
}

.nav__item > a {
  background-color: #002244;
  box-shadow: inset 1px 1px 2px #222, 2px 2px 4px #111111;
  color: white;
  display: block;
  padding: 0.5em;
  text-align: center;
}

.nav__item__img {
  filter: invert(1);
  height: 2rem;
  width: 2rem;
}

@media screen and (min-width: 30rem) {
  .nav__list {
    display: flex;
  }
}

@media screen and (min-width: 45rem) {
  .nav__item {
    flex: 0 0 auto;
  }

  .nav__list {
    flex-direction: column;
  }

  .grid {
    gap: 1rem;
    grid-template: "nav main" auto / auto 1fr;
  }

  .nav__list {
    display: flex;
    flex-flow: column wrap;
    position: sticky;
    top: 0;
  }
}

.aside {
  border: 1px solid black;
  box-sizing: border-box;
  min-height: calc(100vh - 1rem);
  margin: 0;
  padding: 1rem;
}

@media screen and (min-width: 64rem) {
  .aside {
    border: 0px;
    height: auto;
    min-height: auto;
    padding: 0;
    margin: 0;
  }

  .aside > .sticky {
    position: sticky;
    top: 0;
  }
}

.details__table {
  border: 1px solid black;
  border-collapse: collapse;
}

.details__table__thead__header__img {
  border: 0;
  display: inline;
  height: 2.5rem;
  margin-left: 0.5em;
  vertical-align: middle;
  width: 2.5rem;
}

.details__table__header {
  border-right: 1px solid black;
  padding: 0.25em 0.5em 0.25em 0.25em;
  text-align: right;
  vertical-align: top;
}

.details__table__cell {
  padding: 0.25em 0.5em 0.25em 0.25em;
  vertical-align: top;
}

.details__table__thead__header {
  border-bottom: 1px solid black;
  text-align: left;
}
