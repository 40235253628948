.currency {
  align-items: center;
  background-color: transparent;
  break-inside: avoid;
  display: grid;
  gap: 0.5rem;
  grid-template:
    "name amount image" auto
    "description description description" auto / 1fr auto auto;
  list-style: none;
  padding: 0.5rem;
}

.currency:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.2);
}

.currency__amount {
  grid-area: amount;
  text-align: left;
}

.currency__description {
  font-family: sans-serif;
  font-size: 0.875rem;
  grid-area: description;
}

.currency__img {
  grid-area: image;
  height: 2rem;
  width: 2rem;
}

.currency__name {
  grid-area: name;
}
